import { webmotorsSolutionService } from 'application'
import { useMemo } from 'react'
import { WebmotorsSolutionsCard } from '../../Cards'
import * as Styles from './WebmotorsSolutions.styles'

export function WebmotorsSolutions() {
  const data = useMemo(
    () => webmotorsSolutionService.getAllFromConfigData(),
    []
  )

  return (
    <Styles.Container>
      <Styles.Typography
        variant="h2"
        color="textSecondary"
        mb={2}
        data-testid="WebmotorsSolutionsTitle"
      >
        Soluções Webmotors
      </Styles.Typography>
      <Styles.CardRow>
        {data?.map((solution, index) => (
          <WebmotorsSolutionsCard key={index} {...solution} />
        ))}
      </Styles.CardRow>
    </Styles.Container>
  )
}
