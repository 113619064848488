import { ROUTES } from 'constants/index'
import { WebmotorsSolution } from 'domain/WebmotorsSolution'
import financing from 'presentation/assets/wmsolutions/financing.svg'
import sell from 'presentation/assets/wmsolutions/sell.svg'
import wmservices from 'presentation/assets/wmsolutions/wmservices.svg'

interface IDefaultAnchorAttributes {
  rel: 'noreferrer' | 'nofollow' | 'noopener'
  target: '_blank' | '_self'
}

const defaultAttributes: IDefaultAnchorAttributes = {
  rel: 'noreferrer',
  target: '_blank'
}

export const webmotorsSolutionLinks: WebmotorsSolution[] = [
  // {
  //   id: 'WebmotorsSolution_subscriptionCars',
  //   titleDesktop: 'Carros por assinatura',
  //   titleMobile: 'Carros por Assinatura',
  //   spanText: 'NOVO',
  //   mainText: 'Compare e encontre as melhores ofertas.',
  //   image: subscriptioncars,
  //   imageAlt: 'Um carro com tag de assinatura.',
  //   data_qa: 'icon_webmotorsSolution_subscriptionCars',
  //   url: `${ROUTES.WEBMOTORS_SOLUTION.SUBSCRIPTION_CARS}?lkid=3799`,
  //   attributes: {
  //     ...defaultAttributes,
  //     onClick: () => {
  //       const eventKey = 'userInteraction'
  //       window.objDataLayer?.page &&
  //         (window.objDataLayer.page.interaction = 'carros-por-assinatura')
  //       console.info(`[EventSend] eventType: ${eventKey}`)
  //       window.dispatchEvent(
  //         new CustomEvent(eventKey, {
  //           detail: window.objDataLayer
  //         })
  //       )
  //     }
  //   }
  // },
  {
    id: 'WebmotorsSolution_webmotorsServices',
    titleDesktop: 'Webmotors Serviços',
    titleMobile: 'Webmotors Serviços',
    spanText: 'NOVO',
    mainText: 'Funilaria, manutenção e mais em oficinas perto de você.',
    image: wmservices,
    imageAlt: 'Uma chave de boca.',
    data_qa: 'icon_webmotorsSolution_webmotorsServices',
    url: `${ROUTES.WEBMOTORS_SOLUTION.WEBMOTORS_SERVICES}?lkid=3802`,
    attributes: {
      ...defaultAttributes,
      onClick: () => {
        const eventKey = 'userInteraction'
        window.objDataLayer?.page &&
          (window.objDataLayer.page.interaction = 'webmotors-servicos')
        console.info(`[EventSend] eventType: ${eventKey}`)
        window.dispatchEvent(
          new CustomEvent(eventKey, {
            detail: window.objDataLayer
          })
        )
      }
    }
  },
  {
    id: 'WebmotorsSolution_sell',
    titleDesktop: 'Vender',
    titleMobile: 'Quero Vender',
    mainText:
      'Venda fácil e rápido. Anuncie para milhões e feche o melhor negócio.',
    image: sell,
    imageAlt: 'Um carro com uma tag de dinheiro na frente.',
    data_qa: 'icon_webmotorsSolution_sell',
    url: `${ROUTES.WEBMOTORS_SOLUTION.SELL}?lkid=3800`,
    attributes: {
      ...defaultAttributes,
      onClick: () => {
        const eventKey = 'userInteraction'
        window.objDataLayer?.page &&
          (window.objDataLayer.page.interaction = 'vender')
        console.info(`[EventSend] eventType: ${eventKey}`)
        window.dispatchEvent(
          new CustomEvent(eventKey, {
            detail: window.objDataLayer
          })
        )
      }
    }
  },
  {
    id: 'WebmotorsSolution_financing',
    titleDesktop: 'Financiamento',
    titleMobile: 'Quero Financiar',
    mainText:
      'Aproveite milhares de ofertas com parcelas que cabem no seu bolso.',
    image: financing,
    imageAlt: 'Uma tag de dinheiro com um sinal de ciclo em volta do mesmo.',
    data_qa: 'icon_webmotorsSolution_financing',
    url: `${ROUTES.WEBMOTORS_SOLUTION.FINANCING}?lkid=3801`,
    attributes: {
      ...defaultAttributes,
      onClick: () => {
        const eventKey = 'userInteraction'
        window.objDataLayer?.page &&
          (window.objDataLayer.page.interaction = 'financiamentos')
        console.info(`[EventSend] eventType: ${eventKey}`)
        window.dispatchEvent(
          new CustomEvent(eventKey, {
            detail: window.objDataLayer
          })
        )
      }
    }
  }
]
